










































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {dateToYMD, dateToYMD_HM, formatMonth, formatNoteDate} from '@/utils/helpers';
import ChatInfoUser from '@/components/ChatInfoUser.vue';
import {requestsStore} from '@/store/modules/requests/RequestsStore';
import {profileStore} from '@/store/modules/profile';
import PopupMenu from '@/components/PopupMenu.vue';
import DialogScheduleAppointment from "@/components/directory/customer/DialogScheduleAppointment.vue";
import DialogRescheduleAppointment from "@/components/directory/customer/DialogRescheduleAppointment.vue";
import {Action, Getter} from "vuex-class";
import {mixins} from "vue-class-component";
import Notifications from '@/components/mixins/Notifications';

@Component({
  name: 'appointment-calendar',
  components: {PopupMenu, ChatInfoUser,
    DialogScheduleAppointment, DialogRescheduleAppointment
  },
  filters: {
    dateToYMD, formatNoteDate
  }
})
export default class AppointmentsCalendar extends mixins(Vue, Notifications) {
  @Prop({type: Array}) today;
  @Prop({type: Array}) upcoming;
  @Prop({type: Array}) conducted;
  @Prop({type: Array}) canceled;
  @Prop({type: Boolean, default: false}) historyMode;

  focus: string = dateToYMD(new Date());
  type: string = 'month';
  typeToLabel: any = {
    month: 'Month',
    week: 'Week',
    day: 'Day',
    '4day': '4 Days',
  };

  start: any = null;
  end: any = null;
  selectedEvent: any = {};
  selectedElement: any = null;
  selectedOpen: any = false;
  showDialogSchedule: boolean = false
  showDialogReschedule: boolean = false;
  appointment: any | null = null;

  eventsForDate(date) {
    return this.events?.filter(item => item.date === date) || [];
  }

  get labelsMenu() {
    return [
      {title: 'Day', type: 'day'},
      {title: 'Week', type: 'week'},
      {title: 'Month', type: 'month'},
      {title: '4 days', type: '4day'}
    ]
  }

  get showActions() {
    return this.selectedEvent.owner
        && !this.selectedEvent.conducted
        && !this.selectedEvent.canceled
  }

  get selectedAppointmentId(): string {
    const {getSelectedAppointment} = requestsStore
    return getSelectedAppointment && getSelectedAppointment.id;
  }

  get appointments(): any[] {
    const {today, upcoming, conducted, canceled} = this
    return this.historyMode ? [...(conducted || []), ...(canceled || [])] : [...(today || []), ...(upcoming || [])];
  }

  get events() {
    const userId = profileStore.t2bUser?.id
    return this.appointments && this.appointments.map<any>(appoint => {
      return {
        id: appoint.id,
        name: appoint.customer.fullName,
        customerId: appoint.customer.id,
        photoUrl: (appoint.customer.photoUrl && appoint.customer.photoUrl.thumbnail) || null,
        date: dateToYMD(appoint.startDate.toDate()),
        start: dateToYMD_HM(appoint.startDate.toDate()),
        end: dateToYMD_HM(appoint.endDate.toDate()),
        details: appoint.comment,
        color: appoint.selected ? 'primary' : 'white',
        value: false,
        timer: appoint.timer,
        events: appoint.events,
        canceled: appoint.canceled,
        conducted: appoint.conducted,
        owner: appoint.memberIDs.includes(userId),
        associateName: appoint.associate.name,
        associatePhotoUrl: appoint.associate.photoUrl?.thumbnail
      };
    });
  }

  get nowDate() {
    return new Date();
  }

  get title() {
    const {start, end} = this;
    if (!start || !end) {
      return '';
    }
    const startMonth = formatMonth(start.date);
    const endMonth = formatMonth(end.date);
    const suffixMonth = startMonth === endMonth ? '' : endMonth;

    const startYear = start.year;
    const endYear = end.year;
    const suffixYear = startYear === endYear ? '' : endYear;

    const startDay = start.day + this.nth(start.day);
    const endDay = end.day + this.nth(end.day);

    switch (this.type) {
      case 'month':
        return `${startMonth} ${startYear}`;
      case 'week':
      case '4day':
        return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
      case 'day':
        return `${startMonth} ${startDay} ${startYear}`;
    }
    return '';
  }

  get showEventCallback() {
    const {events, showAppointEvent} = this
    return function(afterId) {
      return function() {
        const element = document.getElementById(afterId);
        if (!!element) {
          showAppointEvent(element, events.find((evt) => evt.id === afterId))
        }
      }
    }
  };

  @Getter selectedCustomer;
  @Action loadCustomerById;

  onLabelsMenuItemClicked(menuItem: any) {
    this.type = menuItem.type
  }

  @Watch('selectedAppointmentId')
  onAppointIdChanged(afterId, beforeId) {
    if (!afterId) {
      return
    }
    setTimeout(this.showEventCallback(afterId), 0)
  }

  async onCancelEvent(event) {
    try {
      await requestsStore.cancelAppointment(event.id);
      this.selectedOpen = false;
      this.selectedElement = null;
      this.selectedEvent = {};
    } catch (e) {
      console.log(e);
    }
  }

  onReschedule(event) {
    this.appointment = this.selectAppointmentById(event.id);
    this.showDialogReschedule = true;
  }

  onCloseEvent() {
    this.selectedOpen = false;
    this.selectedElement = null;
    this.selectedEvent = {};
  }

  async onAddAppointment() {
    try {
      await requestsStore.cancelAppointment(this.selectedEvent.id);
      this.selectedOpen = false;
      this.selectedElement = null;
      this.selectedEvent = {};
    } catch (e) {
      console.log(e);
    }
  }

  selectAppointmentById(appointmentId) {
    return this.today.concat(this.upcoming).filter(item => item.id === appointmentId)[0];
  }

  eventStatusColorStyle(event) {
    return {
      color: event.color
    };
  }

  viewDay({date}) {
    this.focus = date;
    this.type = 'day';
  }

  getEventColor(event) {
    return event.color;
  }

  setToday() {
    this.focus = dateToYMD(this.nowDate);
  }

  prev() {
    const calendar: any = this.$refs.calendar;
    calendar.prev();
  }

  next() {
    const calendar: any = this.$refs.calendar;
    calendar.next();
  }

  showEvent({nativeEvent, event}) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => this.selectedOpen = true, 10);
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }
    nativeEvent.stopPropagation();
  }

  showAppointEvent(element, event) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = element;
      setTimeout(() => this.selectedOpen = true, 10);
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }
  }

  async changeMember(event) {
    this.loadCustomerById(event.customerId)
        .then(() => this.showDialogSchedule = true)
        .catch(e => {
          console.log(`loadCustomerById`, e);
        });
  }

  getEvents({start, end}) {
    // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
    this.start = start;
    this.end = end;
    console.log(`updateRange => start=${start.date}, end=${end.date}`)
    return this.events?.filter((item) => start <= item.date <= end) || []
  }

  nth(d) {
    return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10];
  }

  mounted() {
    const calendar: any = this.$refs.calendar;
    calendar.checkChange();
  }
}
