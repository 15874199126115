












































import {Component, Prop, Vue} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import { formatNoteDate, timeToFrom} from '@/utils/helpers';
import PopupMenu from '@/components/PopupMenu.vue';

@Component({
  name: 'appointment-list-item',
  components: {PopupMenu, AvatarWithStatus, GroupAvatar},
  filters: {
    formatNoteDate
  }
})
export default class AppointmentListItem extends Vue {
  @Prop() item?: any;
  @Prop() imageUrl?: string;
  @Prop() imageUrls?: any;
  @Prop() userId?: string;
  @Prop({default: false}) online?: boolean;
  @Prop({default: false}) selected?: boolean;

  get ownAppoint() {
    return this.item.memberIDs.includes(this.userId)
  }

  get menu() {
    return [
      {
        title: 'Reschedule',
        event: 'on-reschedule',
        skip: this.item.conducted || this.item.canceled
      },
      {
        title: 'Cancel',
        event: 'on-cancel',
        skip: this.item.conducted, alert: true
      }
    ]
  }

  onMenuItemClicked(menuItem: any) {
    this.$emit(menuItem.event, this.item.id)
  }

  created() {
    const countDownDate = this.item.startDate.toDate().getTime();
    this.item.timer = timeToFrom(countDownDate, 'Expired');
  }
};
