


















































































































































































import BaseDialog from '@/components/BaseDialog.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {dateToYMD, formatMonthDayYear, isFuture} from '@/utils/helpers';
import ContactsList from '@/components/ContactsList.vue';
import {BusinessContact} from '@/domain/model/types';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {requestsStore} from '@/store/modules/requests/RequestsStore';
import DaySwitcher from "@/components/custom/DaySwitcher.vue";
import GpDaySwitcher from "@/components/custom/DaySwitcher.vue";
import {settingsStore} from "@/store/modules/settings";

@Component({name: 'dialog-schedule-appointment', components: {
    GpDaySwitcher,
    AvatarWithStatus, ContactsList, BaseDialog, DaySwitcher},
  filters: { formatMonthDayYear }
})
export default class DialogScheduleAppointment extends Vue {

  @Prop({type: Boolean, default: false}) value
  @Prop({type: Object, required: true}) customer

  daysOfWeek: string[] = ['Sun', 'Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat']
  daysOfWeekIndexed: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  start: string = dateToYMD(new Date())
  pickContact: number = 0
  pickDay: number = 1
  pickTime: number = 2
  complete: number = 3
  reminder: number = 4
  currentStep: number = 0
  selectedContact: BusinessContact | null = null
  selectedTimeRange: string | null = null
  selectedReminder: string | null = null
  appointDays: any[] = []
  bookedDates: string[] = []
  editComment: boolean = false;
  comment: string = '';

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get title() {
    switch (this.currentStep) {
      case this.pickContact:
        return 'Select a Contact'
      case this.pickDay:
      case this.pickTime:
        return 'Select Date and Time'
      default:
        return 'Schedule an Appointment'
    }
  }

  get subtitle() {
    switch (this.currentStep) {
      case this.pickContact:
        return 'Select a contact to assign an appointment'
      case this.reminder:
        return 'Set a Reminder'
      default:
        return ''
    }
  }

  get weekDay() {
    return new Date(this.start).getDay()
  }

  get dayTimeRange() {
    const dayName = this.daysOfWeekIndexed[this.weekDay]
    return this.appointDays.find((day) => day.name === dayName)
  }

  get times() {
    if (!this.dayTimeRange) {
      return []
    }
    let {start, end, period} = this.dayTimeRange
    let nowDate: Date = new Date();
    const startSplit = start.split(' ');
    const startFmt = startSplit[1]
    const startSplitTime = startSplit[0].split(':');
    let startHour: number = parseInt(startSplitTime[0], 10)
    let startMinutes: number = parseInt(startSplitTime[1], 10)
    if (this.start === dateToYMD(nowDate) && nowDate.getHours() > (startFmt === 'AM' ? startHour : startHour + 12)) {
      if (period * Math.ceil(nowDate.getMinutes()/period) < 60) {
        startHour = nowDate.getHours();
        startMinutes = period * Math.ceil(nowDate.getMinutes()/period);
      } else {
        startHour = nowDate.getHours() + 1;
      }
      const zeroMenu: string = startMinutes < 10 ? '0': '';
      if (startHour < 12) {
        start = `${startHour}:${zeroMenu}${startMinutes} AM`;
      } else {
        start = `${startHour-12}:${zeroMenu}${startMinutes} PM`;
      }
    }
    const endSplit = end.split(' ');
    const endFmt = endSplit[1]
    const endSplitTime = endSplit[0].split(':');
    const endHour: number = parseInt(endSplitTime[0], 10)

    const startHour24 = startFmt === 'AM' ? startHour : startHour + 12
    const endHour24 = endFmt === 'AM' ? endHour : endHour + 12
    const diffMinutes = (endHour24 - startHour24) * 60
    const timeSlots = diffMinutes / period

    const slots: string[] = []
    for (let i = 0; i < timeSlots; i++) {
      startMinutes += period
      if (startMinutes >= 60) {
        startHour++
        startMinutes -= 60
      }
      end = `${startHour < 12 ? startHour : startHour - 12}:${startMinutes <= 9 ? `0${startMinutes}` : startMinutes} ${startHour < 12 ? 'AM' : 'PM'}`
      if (this.bookedDates.indexOf(start) == -1) {
        slots.push(`${start} - ${end}`)
      }
      start = end
    }
    return slots
  }

  get reminderList(){
    return [5, 10, 15, 20, 25, 30].map(item => `${item} min before`);
  }

  get timeZone() {
    return settingsStore.settings?.timeZone || '';
  }

  allowedDates(val) {
    const date = new Date(val)
    const weekDay = date.getDay();
    return this.appointDays.find((item) => item.name === this.daysOfWeekIndexed[weekDay]).active === true && isFuture(date)
  }

  dayFormat(date) {
    const index = new Date(date).getDay()
    return this.daysOfWeek[index]
  }


  async onContactSelected(contact: BusinessContact[]) {
    this.selectedContact = contact[0];
    if (!!this.selectedContact) {
      this.appointDays = await directoryStore.loadAppointDays(this.selectedContact.associate!.id!)
    }
  }

  async onDateSelected(date: string) {
    this.currentStep++
    if (!!this.selectedContact) {
      const startDate = new Date(date);
      const day = this.appointDays.find((day, index) => index === startDate.getDay());
      const startTime = day.start
      const startSplit = startTime.split(' ');
      const startFmt = startSplit[1]
      const startSplitTime = startSplit[0].split(':');
      const startHour: number = parseInt(startSplitTime[0], 10)
      const startMinutes: number = parseInt(startSplitTime[1], 10)
      const from = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startFmt === 'AM' ? startHour : startHour + 12, startMinutes);

      const endTime = day.end
      const endSplit = endTime.split(' ');
      const endFmt = endSplit[1]
      const endSplitTime = endSplit[0].split(':');
      const endHour: number = parseInt(endSplitTime[0], 10)
      const endMinutes: number = parseInt(endSplitTime[1], 10)
      const to = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), endFmt === 'AM' ? endHour : endHour + 12, endMinutes);
      const data: any = {
        associateId: this.selectedContact.associate!.id!,
        from,
        to
      };
      this.bookedDates = await directoryStore.loadBookedDates(data)
    }
  }

  async onScheduleAppointment() {
    if (!this.selectedTimeRange) {
      return
    }
    const date: Date = new Date(this.start)
    const splitRange = this.selectedTimeRange.split('-')

    const startTime = splitRange[0]?.trim()
    const startSplit = startTime.split(' ');
    const startFmt = startSplit[1]
    const startSplitTime = startSplit[0].split(':');
    const startHour: number = parseInt(startSplitTime[0], 10)
    const startMinutes: number = parseInt(startSplitTime[1], 10)
    const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), startFmt === 'AM' ? startHour : startHour + 12, startMinutes);

    const endTime = splitRange[1]?.trim()
    const endSplit = endTime.split(' ');
    const endFmt = endSplit[1]
    const endSplitTime = endSplit[0].split(':');
    const endHour: number = parseInt(endSplitTime[0], 10)
    const endMinutes: number = parseInt(endSplitTime[1], 10)
    const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), endFmt === 'AM' ? endHour : endHour + 12, endMinutes);
    const remind: number = this.selectedReminder ? parseInt(this.selectedReminder.replace(/[^0-9.]/g, '')) * 60000 : 0
    console.log('onScheduleAppointment', remind);
    await requestsStore.scheduleAppointment({
      customerId: this.customer.id,
      associateId: this.selectedContact!.id,
      remind,
      startDate,
      endDate,
      comment: this.comment
    })
    this.$emit('add-appointment');
    this.show = false
  }
}
